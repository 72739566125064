<template>
	<div :style="bg" >
		<div class="column-center-content main-content" style="margin-top: 10px; margin-bottom:  0px;">
			<div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; padding: 0px 10px;">
				
				<div v-for="item in mallList" :key="item.id" @click="goDetail(item)" style="width: 48%; background-color: #FFFFFF; margin: 10px 0px; text-align: center; border-radius: 10px;">
					<img :src="item.img" width="100%" style="border-top-left-radius: 10px; border-top-right-radius: 10px;"/>
					<div style="padding: 5px 10px;">
						<p style="margin: 0px 0px 5px 0px; font-weight: bold; font-size: 18px;">{{item.name}}</p>
						<p v-if="item.coin == 'WDT'" style="color: #FF0000; text-align: left; margin-bottom: 5px;">{{item.wdtPrice}} WDT</p>
						<p v-else-if="item.coin == 'USDM'" style="color: #FF0000; text-align: left; margin-bottom: 5px;">{{item.usdmPrice}} USDM</p>
						<p v-else style="color: #FF0000; text-align: left; margin-bottom: 5px;">{{item.pbtPrice}} PBT</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	
	export default {
		name: 'GoodsList',
		components: {
			
		},
		data:() => ({
			bg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			mallList: [],
			wdtFee: ''
		}),
		mounted() {
			Indicator.open({
				text: '',
				spinnerType: 'fading-circle'
			});
			
			this.$request.dict({
				"label" : 'WDT',
				"type" : "am_goods_price"
			}).then((dict)=>{
				this.wdtFee = dict.price;
			});
			
			this.$request.mallList({}).then((data)=>{
				Indicator.close();
				data.some((item)=>{
					item.wdtPrice = (item.price * (1 - item.usdt)) / this.wdtFee;
					item.usdmPrice = item.price * (1 - item.usdt);
					item.pbtPrice = item.price * 20;
				})
				this.mallList = data;				
			});
		},
		methods: {
			goDetail(item) {
				this.$router.push({
					name: 'GoodsDetail',
					params: item
				})
			}
		}
	}
</script>

<style>
</style>
